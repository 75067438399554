<template>
    <div>
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>
import { commonData } from "../../../sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "../../../commonMethodMixin";
import { mapActions } from "vuex";
export default {
    name: "equipmentdata_crack_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: "equipmentdata_crack_form",
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            // 筛选项
            itemId: '70304',
            bhlx: '60',
            keyword: "", // 关键字
            startValue: '',
            endValue: '',
        };
    },
    mounted() {},
    methods: {
        ...mapActions([
            "DeleteDiseaseMonitorData",
            "GetDiseaseMonitorDataList"
        ]),
        async getTableData() {
            this.startValue = "";
            this.endValue = "";
            if (this.dateSearchData && this.dateSearchData.length > 0) {
                this.dateSearchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (item.value !== null &&
                            typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "startTime") {
                            this.startValue = `${item.value}`;
                        } else if (item.type === "endTime") {
                            this.endValue = `${item.value}`;
                        }
                    }
                });
            }

            this.tableGroupAttributes.loading = true;
            let res = await this.GetDiseaseMonitorDataList({
                itemId: this.itemId,
                keyWord: this.keyword,
                sort: null,
                sortorder: null,
                start: this.startValue,
                end: this.endValue,
                bhlx: this.bhlx,
                shzt: null,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu
            });
            if (
                res &&
                res.Data &&
                res.Data.length > 0 &&
                res.Data[0] &&
                res.Data[0].Data &&
                res.Data[0].Data.length > 0
            ) {
                this.tableGroupAttributes.snTable.columns.columnsData[3] = {
                    label: `宽度(${res.Data[0].Data[0].DW})`,
                    prop: "JCZ"
                };
                this.tableGroupAttributes.snTable.table.data = res.Data[0].Data || [];
                this.tableGroupAttributes.snPage.count = Number(res.Data[0].Data.length) || 0;
            }else{
                this.tableGroupAttributes.snTable.table.data = [];
                this.tableGroupAttributes.snPage.count = 0;
            }
            this.tableGroupAttributes.loading = false;
        },
        exportData() {
            let url = window.REQUEST_URL + 'MonitorData/GetDiseaseMonitorExport';
            let itemId = this.itemId;
            let keyWord = this.keyword;
            let start = this.startValue;
            let end = this.endValue;
            let bhlx = this.bhlx;

            let exportUrl = `${url}?itemId=${itemId}&keyWord=${keyWord}&start=${start}&end=${end}&bhlx=${bhlx}`;

            window.open(exportUrl);
        },
        // 删除数据
        async delData(id, done, instance, value) {
            let res = await this.DeleteDiseaseMonitorData({
                // bhidandrksj: [{ cjsj: value.CJSJ, bhid: value.BHID }],
                // itemCode: this.itemId,
                // bhlx: this.bhlx
                id: value.ID
            });
            this.delOver(res, done);
        }
    }
};
</script>
<style lang="scss" scoped>
</style>